/* stylelint-disable */
.wpsm_panel-heading{
	padding:0px !important;
}
.wpsm_panel-title {
	margin:0px !important; 
	text-transform:none !important;
	line-height: 1 !important;
}
.wpsm_panel-title a{
	text-decoration:none;
	overflow:hidden;
	display:block;
	padding:0px;
	font-size: 18px !important;
	font-family: Tahoma !important;
	color:#ffffff !important;
	border-bottom:0px !important;
}

.wpsm_panel-title a:focus {
outline: 0px !important;
}

.wpsm_panel-title a:hover, .wpsm_panel-title a:focus {
	color:#ffffff !important;
}
.acc-a{
	color: #ffffff !important;
	background-color:#f76707 !important;
	border-color: #ddd;
}
.wpsm_panel-default > .wpsm_panel-heading{
	color: #ffffff !important;
	background-color: #f76707 !important;
	border-color: #f76707 !important;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.wpsm_panel-default {
	border:1px solid transparent !important;
	margin-bottom: 20px;
	overflow: hidden;
	float: none;
	width: 100%;
	display: block;
}
.ac_title_class{
	display: block;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 15px;
	padding-right: 15px;
}
 .wpsm_panel {
	overflow:hidden;
	-webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, .05);
	box-shadow: 0 0px 0px rgba(0, 0, 0, .05);
		border-radius: 0px;
	}
 .wpsm_panel + .wpsm_panel {
		margin-top: 5px;
	}
 .wpsm_panel-body{
	background-color:#ffffff !important;
	color:#000000 !important;
	border-top-color: #f76707 !important;
	font-size:16px !important;
	font-family: Tahoma !important;
	overflow: hidden;
		border: 2px solid #f76707 !important;
	}

.ac_open_cl_icon{
	background-color:#f76707 !important;
	color: #ffffff !important;
	float:right !important;
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	line-height: 1.0 !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	display: inline-block !important;
}
.wpsm_panel-body p {
	padding: 15px;
}
#gform_submit_button_1 {
	width: 100px;
}