/** @define landing-php */
.landing-php {
  height: 100%;

  @include media('<desktop') {
    background: none;
    height: auto;
  }

}

/** @define full-page-slider */
.full-page-slider {
  @include position-zero;
  position: absolute;

}

/** @define landing-holder */
.landing-holder {
  height: 100vh;
  padding: 76px 0 116px;
  position: relative;

  @include media('<desktop') {
    height: auto;
  }

  @include media('<desktop') {
    padding-bottom: 300px;
  }
}
/** @define landing-box */
.landing-box {
  margin: 0 auto;
  max-width: 558px;
  position: relative;

  &__inner {
    background: rgba(255, 255, 255, 0.77);
    margin: 0 7px;
    padding: 29px 38px 50px;
  }
}
/** @define landing-logo */
.landing-logo {
  padding: 0;
  text-align: center;
}
/** @define landing-description */
.landing-description {
  @include font(16px, 23px);
  color: $gray2;
  letter-spacing: -0.2px;
  position: relative;
  text-align: center;

  &__text {
    @include font(21px, 25px);
    font-weight: 700;
    margin: 20px 0 0;
  }
}
/** @define info-box-holder */
.info-box-holder {
  display: flex;
  margin-top: 35px;
  position: relative;

  @include media('<tablet') {
    display: block;
    overflow: hidden;
  }

}
/** @define info-box */
.info-box {
  @include font(14px, 17px);
  background: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0 , 0.1);
  box-sizing: border-box;
  color: $green;
  padding: 17px 44px 20px;
  text-align: center;
  width: 47%;

  @include media('<tablet') {
    float: none;
    margin: 0 0 20px;
    width: 100%;

  }
  
  &__heading {
    @include font(21px, 24px);
    color: $green;
    font-weight: 700;
    margin: 14px 0 5px;
    text-transform: uppercase;

  }

  &__address {
    display: block;
  }

  &__tell {
    display: block;
    margin-top: 9px;
  }
}
/** @define landing-footer */
.landing-footer {
  background: url('../front-end-src/images/bg-footer.png') no-repeat 50% 0;
  bottom: 0;
  height: 138px;
  left: 0;
  position: absolute;
  right: 0;

  @include media('<desktop') {
    height: 320px;
  }

}
/** @define zippy */
.zippy {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 99;

  @include media('<widescreen') {
    display: none;
  }
}
/** @define footer-logo */
.footer-logo {
  bottom: 17px;
  left: 16px;
  position: absolute;
  z-index: 99;

  @include media('<desktop') {
    left: 0;
    padding-top: 90px;
    position: relative;
    right: 0;
    top: 0;

    &::after {
      background: $brown;
      content: '';
      left: 0;
      margin: 0 0 -999px;
      padding: 0 0 999px;
      position: absolute;
      right: 0;
      top: 80%;
    }
  }

  &__list {
    @include listreset;

    @include media('<desktop') {
      text-align: center;
    }

    &--li {
      float: left;
      margin-left: 12px;

      @include media('<desktop') {
        display: inline-block;
        float: none;
        position: relative;
        vertical-align: middle;
        z-index: 99;
      }


    }
  }
}
/** @define landing-social */
.landing-social {
  @include font(12px, 15px);
  @include listreset;
  font-weight: 700;
  letter-spacing: 2.6px;
  padding-top: 86px;
  text-align: center;
  text-transform: uppercase;

  @include media('<desktop') {
    padding-top: 20px;
  }

  &__li {
    display: inline-block;
    padding: 0 16px;
    vertical-align: top;

    @include media('<desktop') {
      display: block;
      margin-bottom: 10px;
      position: relative;
      text-align: center;
      z-index: 99;
    }

    &--a {
      color: $white;
      font-weight: 700;
    }
  }
}

 /* stylelint-disable */
.landing-logo {
  @include media('<tablet') {
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}
.landing-php {
  .carousel {
    @include position-zero;
    position: absolute; 
  }
  .carousel-inner { 
    padding: 0;
    @include position-zero;
    position: absolute;
    .item {
      @include position-zero;
      position: absolute;
    }
  }
  .wrapper {
    height: 100%;
  }
}
.landing-description {
  p { margin: 30px 0 0;}
}
.info-box {
  a {
    color: $green;
    &:hover {
      color: $brown;
    }
  }
  .btn-primary {
    margin-top: 15px;
    color: $white;
    &:hover { color: $white;}
  }
  img {
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
  }
  &.pull-right {
    margin-left: 6%;
    color: $orange;
    .info-box__heading {
      color: $orange;
      margin-top: 21px;
    }
    img {
      display: inline-block;
      vertical-align: top;
      margin-top: 7px;
    }
    a {
      color: $orange;
      &:hover {
        color: $black;
      }
    }
    .btn-primary {
      background: $orange;
      color: $white;
      &:hover {
        background: $brown;
        color: $white;
      }
    }
  }
 }
.landing-social__li--a {
  .icon {
    color: $white;
    display: inline-block;
    vertical-align: top;
    margin: 2px 3px 0 0; 
  }
  &:hover {
    color: $orange;
    text-decoration: none;
    .icon { color: $orange;}
  }
}

.landing-php {
  @media (max-height: 899px) {
    height: auto;
    background: none;
  }
}
.landing-holder {
  @media (max-height: 899px) {
    height: auto;
  }
}
.bg-stretch {
  @include bg-stretch;
}
#landing-Carousel .carousel-inner .item {
  visibility: hidden;
  transition: all 3.6s ease !important;
  opacity: 0;
  display: block !important;
  transform: none !important;
  left: 0 !important;
  right: 0 !important;
  z-index: -9 !important;
}
#landing-Carousel .carousel-inner .item.active {
  visibility: visible;
  opacity: 1;
  transform: none !important;
  z-index: -9 !important;
}




