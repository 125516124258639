/** @define blocks-holder */
.blocks-holder {
  padding: 97px 0 101px;
  position: relative;
  text-align: center;

  @include media('<tablet') {
    padding: 35px 0;
  }
}

/** @define blocks */
.blocks {
  @include font(14px, 17px);
  background: $yellow;
  box-shadow: 20px 20px 0 0 $off-gray;
  box-sizing: border-box;
  color: $white;
  display: inline-block;
  font-weight: 300;
  margin: 0 39px;
  padding: 48px 40px 60px;
  vertical-align: top;
  width: 264px;

  @include media('<desktop') {
    box-shadow: 5px 5px 0 0 $off-gray;
    margin: 0 10px;
    padding: 48px 10px 60px;
    width: 210px;
  }

  @include media('<tablet') {
    margin: 0 0 20px;
    width: 100%;
  }
  
  &__ico {
    display: block;
    font-size: 59px;
  }

  &__heading {
    @include font(21px, 25px);
    color: $white;
    letter-spacing: 0.6px;
    margin: 18px 0 6px;
  }
}
/** @define testimonial-area */
.testimonial-area {
  background: #006207;
  padding: 75px 0 182px;

  @include media('<desktop') {
    padding: 40px 0 110px;
  }

  &__img {
    box-shadow: 20px 20px 0 0 #005200;
    margin: 0 0 0 -36px;

    @include media('<desktop') {
      box-shadow: 5px 5px 0 0 #005200;
      margin: 0 0 30px;
    }
  }
}







/* stylelint-disable */
.blocks {
  p { margin: 0;}
  .btn-default {
    background: $white;
    font-weight: 700;
    display: block;
    float: none;
    margin: 19px auto 0;
  }
  &:nth-child(2n) {
    background: $orange;
  }
  &:nth-child(3n) {
    background: $green;
    .blocks__ico {
      transform: rotate(91deg);
      margin: 3px 0 21px 0;
      font-size: 53px;
    }
  }
}
.testimonial-area__img {
  margin-right: 18px;
  margin-left: 0; 
  img { 
    display: block;
    height: auto;
    width: 100%;
  }
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-inner { padding-bottom: 20px;}
.testimonial-area {
  .container { position: relative;}
  blockquote {
    margin: 0;
    padding: 0 4px 83px 18.6%;
    display: block;
    border: none;
    min-height: 348px; 
    @include media('<desktop') {
      padding-left: 20px;
    }

    @include media('<tablet') {
      padding-left: 0;
    }
    q {
      @include font(28px, 35px);
      color: #cbd8cc;
      font-weight: 300;
      display: block;
      text-align: center; 
      position: relative;
      quotes: none;
      padding: 10px 0 0;
      letter-spacing: 0.5px;

      @include media('<desktop') {
        @include font(16px, 22px);
      }

      &:after {
        position: absolute;
        left: -24px;
        top: 0;
        content: '';
        background: url('../front-end-src/images/bg-quote.png') no-repeat;
        width: 31px;
        height: 25px;

        @include media('<desktop') {
          left: -38px;
        }

        @include media('<tablet') {
          left: 50%;
          top: -20px;
          margin-left: -15px;
        }

      }
      img {
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;

        @include media('<tablet') {
          display: block;
          width: 31px;
          margin: 10px auto; 
        }
      }
    }
    cite {
      @include font(21px, 26px);
      display: block;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      margin-top: 20px;
      font-weight: 700;
      color: $white;
    } 
  }
}
.carousel-fade {
  .carousel-control-holder {
    position: absolute;
    width: 108px;
    right: 31.2%;
    left: auto;
    top: auto;
    bottom: 72px;
    margin-right: -150px;

    @include media('<tablet') {
      right: 50%;
      margin-right: -54px;
    }
  }
  .carousel-control {
    width: 55px;
    height: 49px;
    border: 2px solid $white;
    text-align: center;
    text-shadow: none;
    opacity: 1;
    background: none;
    i {
      display: block;
      text-align: center;
      color: $white;
      font-size: 28px;
      padding-top: 9px;
    }
  }
}