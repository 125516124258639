/** @define wrapper */
.wrapper {
  overflow: hidden;
  position: relative;
}

/** @define main-header */
.main-header {
  position: relative;
}

/** @define logo */
.logo {
  left: 0;
  position: absolute;
  top: 3px;

  @include media('<widescreen') {
    left: 15px;
  }

  @include media('<desktop') {
    width: 150px;
  }
}

/** @define header-top */
.header-top {
  @include font(20px, 24px);
  background: $brown;
  color: $orange;
  position: relative;

  &__text {
    color: #f5d427;
    display: inline-block;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding-top: 14px;
    vertical-align: top;

    @include media('<desktop') {
      display: none;
    }
  }
}

/** @define top-link */
.top-link {
  @include font(14px, 20px);
  @include listreset;
  float: right;
  font-family: 'mr-eaves-xl-modern',sans-serif;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  &__li {
    float: left;
    padding: 15px 29px 15px 17px;

    @include media('<tablet') {
      padding: 15px 12px 15px 10px;
    }

    &:nth-child(2n) {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;

      @include media('<tablet') {
        margin: 0 -9px 0 14px;
      }
    }

    &:nth-child(3n) {

      @include media('<tablet') {
        display: none;
      }
    }

    &:last-child { 
      padding: 0;

      @include media('<tablet') {
        display: block;
      }
    }

    &--a {
      color: $white;
      position: relative;
    }
  }
}
/** @define enroll */
.enroll {
  @include font(16px, 50px);
  background: $orange;
  color: $white;
  display: block;
  font-family: 'mr-eaves-xl-modern',sans-serif;
  font-weight: 700;
  height: 50px;
  letter-spacing: 0;
  line-height: 50px;
  padding-left: 12px;
  position: relative;
  text-align: center;
  width: 182px;

  @include media('<tablet') {
    width: 150px;
  }

  &:hover {
    background: $white;
    color: $orange;
  }

  &:focus {
    color: $white;
  }

  &__ico {
    background: url('../front-end-src/images/ico-sprite.png') no-repeat;
    height: 15px;
    left: 29px;
    position: absolute;
    top: 18px;
    width: 16px;
  }
}











/* stylelint-disable */
.main-header {
  z-index: 9999;
  .container {
    position: relative;
  }
}
.logo {
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
.top-link {
  li {
    position: relative;
    .dropdown {
      padding-right: 14px;
      &:after {
        position: absolute;
        right: 0;
        top: 5px;
        content: '';
        border-style: solid;
        border-width: 0 4.5px 5px 4.5px;
        border-color: transparent transparent $white transparent;
      }
      &::hover {
        &:after {
          border-width: 5px 4.5px 0 4.5px;
          border-color: $yellow transparent transparent transparent;
        }
      }
    }
    .dropdown {
      &.collapsed {
        &:after {
          border-width: 5px 4.5px 0 4.5px;
          border-color: $white transparent transparent transparent;
        }
      }
    }
  }
  i {
    position: absolute;
    left: -20px;
    top: -1px;
    font-size: 16px;
  }
  .enroll {
    i {
      transform: rotate(90deg);
      left: 25px;
      top: 18px;
      @include media('<tablet') {
        left: 10px;
      }
    }
  }
}
.drop-holder {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 9999;
  width: 150px;
  a {
    display: block;
    padding: 10px 15px;
    background: $brown;
    color: $white;
    &::hover {
      background: $orange !important;
    }
  }
}
.enroll {
  color: #fff !important;
}
.enroll:hover {
  color: #da631b  !important
}
/*Slider fixes*/ 
.page-template-home 
  {
    .carousel-inner {
    padding-bottom: 100% !important;
    }  
  }
.page-template-home
{
 .bg-stretch{
    height: 100vh;
  }
}
.page-template-home {
  .full-page-slider{
  position: fixed !important;
}
}
.top-link__li {
  list-style: none;
}
.soliloquy-item img{
width:100%;
}
.top-link__li--a:focus,
.top-link__li--a:hover {
    color: #da631b;
  }
.top-link li {
  padding-bottom: 0;
}
#menu-landing-footer li:after {
  content: '';
  background: #fff;
  bottom: 3px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1px;

   @include media('<desktop') {
    display: none;
  }
}
#menu-landing-footer li:first-child:after {
  display: none
}
/* .onscroll {
  display: none;
}
.main-header.sticky .onscroll {
  background: none;
  color: yellow;
  display: block !important;
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 0;
  text-align: left;
}*/
.main-header.sticky .logo {
  top: -6px;
}
.main-header.sticky .logo img {
  width: 140px; 
  height: auto;
}
@media only screen and ( min-width: 991px ){
  #main-navigation.collapse {
    display: block;
  }
}
@media only screen and ( max-width: 900px ){
  /*.main-header.sticky .onscroll{
    display: none !important;
  }*/
  .main-header.sticky .logo {
    top: -33px;
  }
}
@media only screen and ( max-width: 550px ){
  .enroll {
    float: right;
    margin: 0 0 10px;
    height: 35px;
    line-height: 35px;
  }
  .top-link .enroll i {
    top: 10px;
  }
  .top-link li {
    float: none;
    display: inline-block;
  }
  .top-link {
    float: none;
    width: 100%;
    text-align: center;
  }
}
/*.main-header.sticky .header-top__text {
  display: none;
}*/