// Custom mixins here
// Note: /* postcss-bem-linter: ignore */ was added becuase & in mixins is not interpreted by BEM linter

@mixin hide-text {
  overflow: hidden;
  text-indent: -9999px;
}

@mixin img {
  height: auto;
  vertical-align: top;
  width: 100%;
}

// alignment text
@mixin alignment($value1: inline-block, $value2: top) {
  display: $value1;
  vertical-align: $value2;
}

@mixin font-size($value1, $value2: 1.25) {
  font-size: $value1;
  line-height: $value2;
}

@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}

@mixin square($value1) {
  height: $value1;
  width: $value1;
}

@mixin position-zero($value: 0) {
  bottom: $value;
  left: $value;
  right: $value;
  top: $value;
}

@mixin rgba-color($hexcolor, $opacity) {
  color: $hexcolor;
  color: rgba($hexcolor, $opacity);
}

@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity);
}
// custom font mixin
@mixin fontface ($fontfamily, $filename, $weight, $style) {
  /* postcss-bem-linter: ignore */
  font-family: $fontfamily;
  font-style: $style;
  font-weight: $weight;
  src: url('/fonts/#{$filename}.eot');
  src: url('/fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
    url('/fonts/#{$filename}.woff') format('woff'),
    url('/fonts/#{$filename}.ttf') format('truetype'),
    url('/fonts/#{$filename}.svg#{$filename}') format('svg');
  
}

// centeralign
@mixin centeralign($width) {
  display: block;
  left: 50%;
  margin-left: -$width/2;
  position: relative;
  width: $width;
}

// bg-stretch
@mixin bg-stretch {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

// reset for before and after
@mixin pseudo-elements($before-top, $before-right, $before-bottom, $before-left) {
  bottom: $before-bottom;
  content: '';
  left: $before-left;
  position: absolute;
  right: $before-right;
  top: $before-top;
}

// icomoon usage
@mixin icon($content) {
  content: $content;
  font-family: 'icomoon';
  font-weight: normal;
}

// vertical alignment
@mixin inline($alignment) {
  display: inline-block;
  vertical-align: $alignment;
}

@mixin img-full-width {
  display: block;
  height: auto;
  max-width: inherit;
  width: 100%;
}

// font-size
@mixin font($size, $line: 1.25) {
  font-size: $size;
  line-height: $line;
}

@mixin link-color($color) {
  color: $color;
  text-decoration: none;
  /* postcss-bem-linter: ignore */
  &:hover {
    color: darken($color, 15%);
  }
}

@mixin bg-hover($color) {
  background-color: $color;
  /* postcss-bem-linter: ignore */
  &:hover {
    background-color: darken($color, 10%);
  }
}

@mixin position($value) {
  bottom: $value;
  left: $value;
  position: absolute;
  right: $value;
  top: $value;
}

@mixin custom-icon($icon) {
  /* postcss-bem-linter: ignore */
  &::before {
    content: $icon;
    font-family: 'icomoon';
  }
}

@mixin listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

