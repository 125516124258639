 /* stylelint-disable */
 html { height: 100%;}
 body {
  @include font-size(16px, 1.5);
  background: $white;
  font-family: "mr-eaves-xl-modern",sans-serif;
  min-width: 320px;
}
::-webkit-input-placeholder { 
  color: $gray3;
}
:-moz-placeholder { 
  color: $gray3;
}
::-moz-placeholder { 
  color: $gray3;
  opacity: 1;
}
:-ms-input-placeholder { 
  color: $gray3;
}
a { 
  color: #000;
  transition: all 0.4s ease;
}
a:focus {
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}
.enroll:focus {
  color: $white !important;
}
@font-face {
  font-family: 'valley-icons2';
  src:  url('fonts/valley-icons2.eot?jfcxxv');
  src:  url('fonts/valley-icons2.eot?jfcxxv#iefix') format('embedded-opentype'),
    url('fonts/valley-icons2.ttf?jfcxxv') format('truetype'),
    url('fonts/valley-icons2.woff?jfcxxv') format('woff'),
    url('fonts/valley-icons2.svg?jfcxxv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico"], [class*=" ico"] {
  font-family: 'valley-icons2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-pin:before {
  content: "\e900";
}
.ico-click:before {
  content: "\e901";
}
.ico-chat:before {
  content: "\e902";
}
@font-face {
  font-family: "valley-icons";
  src:url("fonts/valley-icons.eot");
  src:url("fonts/valley-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/valley-icons.woff") format("woff"),
    url("fonts/valley-icons.ttf") format("truetype"),
    url("fonts/valley-icons.svg#untitled-font-3") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "valley-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "valley-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telephone:before {
  content: "\61";
}
.icon-facebook:before {
  content: "\62";
}
.icon-location:before {
  content: "\63";
}
.icon-mail:before {
  content: "\65";
}
.icon-twitter:before {
  content: "\66";
}
.icon-instagrem:before {
  content: "\64";
}
.icon-youtube:before {
  content: "\67";
}
.icon-linkedin:before {
  content: "\68";
}
.icon-google-plus:before {
  content: "\69";
}
.icon-github:before {
  content: "\6a";
}
.icon-dribbble:before {
  content: "\6b";
}
.icon-search:before {
  content: "\6c";
}
.icon-pinterest-p:before {
  content: "\6e";
}
.icon-twitter-1:before {
  content: "\6f";
}
.icon-youtube-play:before {
  content: "\70";
}
.icon-facebook-official:before {
  content: "\71";
}
.icon-align-right:before {
  content: "\72";
}
.icon-globe:before {
  content: "\73";
}
.icon-file-text:before {
  content: "\74";
}
.icon-heartbeat:before {
  content: "\75";
}
.icon-right-thin:before {
  content: "\76";
}
.icon-device-mobile:before {
  content: "\77";
}
.icon-tag:before {
  content: "\78";
}
.icon-times-circle:before {
  content: "\79";
}
.icon-left-thin:before {
  content: "\7a";
}
.icon-right-open:before {
  content: "\41";
}
.icon-right-open-big:before {
  content: "\42";
}
.icon-left-open-mini:before {
  content: "\43";
}
.icon-left-open-big:before {
  content: "\44";
}
.icon-up-open-big:before {
  content: "\45";
}
.icon-up-open:before {
  content: "\46";
}
.icon-down-open-big:before {
  content: "\47";
}
.icon-down-open-mini:before {
  content: "\48";
}
.icon-down-open:before {
  content: "\49";
}
.icon-user:before {
  content: "\4a";
}
.icon-user-md:before {
  content: "\4b";
}
.icon-check:before {
  content: "\4c";
}
.icon-linkedin-alt:before {
  content: "\4d";
}
.icon-yelp:before {
  content: "\4e";
}
.icon-houzz:before {
  content: "\6d";
}
.icon-vimeo:before {
  content: "\4f";
}
.icon-skype:before {
  content: "\50";
}
.icon-play:before {
  content: "\51";
}
.icon-align-justify:before {
  content: "\52";
}
.icon-ios-email-outline:before {
  content: "\5a";
}

input[type="submit"]:hover {
  background: $brown;
  color: $white;
}
.btn {
  @include font(10px, 22px);
  background: $green;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  width: 113px;
  height: 22px;
  padding: 0;
  color:$white;
  text-align: center;
  border:0;
  border-radius: 9px;
  font-weight: 700; 
  transition: all 0.4s ease;
  letter-spacing: 1px;
  &:hover { 
    background: $brown;
    text-decoration: none;
  }
}
.btn-primary {
  @include font(10px, 22px);
  background: $green;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  width: 113px;
  height: 22px;
  padding: 0;
  color:$white;
  text-align: center;
  border:0;
  border-radius: 9px;
  font-weight: 700; 
  transition: all 0.4s ease;
  letter-spacing: 1px;
  &:hover { 
    background: $brown;
    text-decoration: none;
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { 
  font-weight: 700;
}
p {
  margin: 30px 0 0;
}
.btn-default {
  @include font(16px, 32px);
  background: $orange;
  box-shadow: none !important;
  float: left;
  border:none;
  width: 142px;
  height: 32px;
  padding: 0;
  text-transform: uppercase;
  color:$gray5;
  text-align: center;
  font-weight: 400;
  border-radius: 15px;
  transition: all 0.4s ease;
  letter-spacing: 0;
  &:hover { 
    background: $gray5;
    color: $white !important;
    text-decoration: none;
  }
  &:after { display: none !important;}
}
.container {
  max-width: 1176px ;
  width: 100% !important;
  @include media('<widescreen') {
    padding: 0 30px !important;
  }
}
.max-container {
  max-width: 1600px ;
  margin: 0 auto;
  position: relative;
}
.max-container:after {
  display: block;
  clear: both;
  content: "";
}
input[type='text'] {
  @include font(16px, 24px);
  width: 100%;
  border: 1px solid $gray5;
  height: 50%;
  padding: 13px 15px;
  color: $gray;
  box-sizing: border-box;

}
input[type='submit'] {
  @include font(16px, 34px);
  background: $orange;
  box-shadow: none !important;
  float: left;
  border:0;
  width: 142px;
  height: 32px;
  padding: 0;
  text-transform: uppercase;
  color:$white;
  text-align: center;
  font-weight: 700;
  border-radius: 14px;
  transition: all 0.4s ease;
  letter-spacing: 0;
  &:hover { 
    background: $brown;
    color:$white !important;
    border-color: $brown;
    text-decoration: none;
  }
  &:after { display: none !important;}
}
.disflex {
  display: flex;
}
h1 {
  @include font(32px, 36px);
  color: $orange;
  margin: 0 0 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
h2 {
  @include font(24px, 28px);
  color: $orange;
  margin: 0 0 24px;
  text-transform: uppercase;
}
h3 {
  @include font(21px, 24px);
  color: $black;
  margin: 20px 0 0;
  text-transform: uppercase;
}
p {
  margin: 14px 0 0;
}
#gform_4 input[type="text"] {
    width: 100%;
}
#gform_submit_button_4 {
    width: 110px;
}
ul.footer-logolist img {
  width: auto;
  height: auto;
}
.top-linkli{
list-style: none;
}
.sub-menu .sub-menu {
  display: none;
  left: 100%;
  top: 0;
  padding-top: 0; 
}
.sub-menu .menu-item:hover .sub-menu { display: block !important;}
/* changed with 8707 wrong hover property added  */
.nav-listli--a:hover {
  color: #da631b;
}
.header-top__text p {
  margin: 0;
}
.no-shadow {
  box-shadow: none !important;
}


.sticky {
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;
    background: #fff;
}
.sticky .logo a {
  float: left;
  width: 210px;
}
.counter__li--number {
    background: none !important;
}
.page-template-home .carousel-inner {
  height: 100vh;
}
