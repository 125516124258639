/** @define main-area2 */
.main-area2 {
  min-height: 400px;
  padding: 76px 0 164px;
  position: relative;

  @include media('<tablet') {
    padding: 35px 0 130px;
  }

  &__holder {
    display: inline-block;
    margin-bottom: 65px;
    padding-bottom: 75px;
    position: relative;
    vertical-align: top;
    width: 100%;
    

    &::after {
      background: $orange;
      bottom: 0;
      content: '';
      height: 4px;
      left: 50%;
      margin-left: -400px;
      position: absolute;
      width: 800px;
    }
  }

}




/* stylelint-disable */
.content {
  @include font(22px, 32px);
  font-weight: 300;
  color: $black;
  position: relative;
  padding: 0 11px 0 5px;
  
  @include media('<tablet') {
      @include font(16px, 26px);
  }

  p {
    margin: 0 0 8px;
  }
  ul {
    @include font(24px, 30px);
    @include listreset;
    padding-top: 10px;
    margin-bottom: 30px;

    @include media('<tablet') {
      @include font(16px, 30px);
      margin-bottom: 15px;
      padding-top: 0;
    }

    li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 12px;
       &:after {
        position: absolute;
        left: 0;
        top: 4px;
        width: 24px;
        content: '';
        height: 24px;
        background: url('../front-end-src/images/ico-list2.png') no-repeat;
       }
    }
  }
  h1 {
    @include font(32px, 35px);
    text-transform: uppercase;
    margin: 0 0 28px; 
    padding: 0 0 19px;
    position: relative;
    letter-spacing: 1px;

    sup {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 6px;

      @include media('<tablet') {
        font-size: 12px;
      }

    }

    @include media('<tablet') {
      @include font(24px, 30px);
      margin-bottom: 15px;
    }


    &:after {
      position: absolute;
      left: 0;
      width: 58px;
      height: 3px;
      content: '';
      background: $orange;
      bottom: 0;
    }
  }
  .alignright {
    position: relative;
    float: right;
    box-shadow: 20px 20px 0 0 $gray6;
    margin: 0 0 20px 28px;
    @include media('<widescreen') {
      width: 40%;
    }
    @include media('<tablet') {
      float: none;
      max-width: 100%;
      width: auto;
      display: block;
      margin: 0 auto 30px;
      box-shadow: 5px 5px 0 0 $gray6;
    }
  }
  .alignleft {
    position: relative;
    float: right;
    box-shadow: -20px 20px 0 0 $gray6;
    margin: 0 28px 20px 0;
    @include media('<widescreen') {
      width: 40%;
    }
    @include media('<tablet') {
      float: none;
      display: block;
      max-width: 100%;
      width: auto;
      margin: 0 auto 30px;
      box-shadow: -5px 5px 0 0 $gray6;
    }
  }
  .aligncenter {
    max-width: 100%;
    display: block;
    position: relative;
    box-shadow: 20px 20px 0 0 $gray6;
    margin: 0 auto 72px;

    @include media('<tablet') {
      box-shadow: 5px 5px 0 0 $gray6;
      margin-bottom: 30px;
    }
  }
}

.entry-content a:hover {
    color: $orange;
}
