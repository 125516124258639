/** @define features */
.features {
  padding: 61px 0 58px;
  position: relative;

  @include media('<desktop') {
    padding-top: 0;
  }

  &__list {
    @include font(20px, 24px);
    @include listreset;
    color: $black;
    font-weight: 300;
    text-align: center;

    @include media('<tablet') {
      @include font(16px, 24px);
      margin: 0 auto;
      text-align: left;
      width: 260px; 
    }

    &--li {
      display: inline-block;
      padding: 0 24px;
      position: relative;
      vertical-align: top;

      @include media('<tablet') {
        display: block;
        margin-bottom: 10px;
      }

      &::after {
        background: url('../front-end-src/images/ico-list.png') no-repeat;
        content: '';
        height: 14px;
        left: 0;
        position: absolute;
        top: 4px;
        width: 18px;
      }
    }
  }
}
/** @define video-section */
.video-section {
  background: $orange;
  padding: 86px 0 87px;
  position: relative;

  @include media('<tablet') {
    padding: 30px 0 32px;
  }

  &__holder {
    box-shadow: 20px 20px 0 0 #ca3d0a;
    left: 5px;
    margin: 0 auto;
    max-width: 766px;
    position: relative;

    @include media('<widescreen') {
      box-shadow: 5px 5px 0 0 #ca3d0a;
      left: 0;
    }

  }
}
/** @define video-caption */
.video-caption {
  @include position-zero;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25px;

  @include media('<tablet') {
    top: 0;
  }

  &__text {
    @include font(33px, 36px);
    color: $white;
    display: block;
    font-weight: 700;
    letter-spacing: 1.6px;
    padding-right: 13px;
    padding-top: 33px;
    text-align: center;
    text-transform: uppercase;

    @include media('<desktop') {
      display: none;
    }
  }
}

/** @define counter-section */
.counter-section {
  position: relative;

  &__img {
    display: block;
    height: auto;
    width: 100%;
    
    @include media('<tablet') {
      left: 50%;
      margin-left: -1000px;
      position: relative;
      width: 2000px;   
    }

    @include media('<phone') {
      left: 50%;
      margin-left: -1200px;
      position: relative;
      width: 2400px;   
    }

  }
}

/** @define counter-content */
.counter-content {
  @include position-zero;
  align-items: center;
  display: flex;
  position: absolute;

  &__inner {
    padding-bottom: 44px;
    width: 100%;

    @include media('<desktop') {
      padding-bottom: 0;
    }
  }

  &__heading {
    @include font(33px, 36px);
    color: $white;
    letter-spacing: 1.8px;
    margin: 0 0 26px;
    text-transform: uppercase;
  }
}

/** @define counter */
.counter {
  padding: 0;
  text-align: center;

  &__li {
    background: $white;
    border: 10px solid $orange;
    border-radius: 100%;
    display: inline-block;
    height: 119px;
    margin: 0 26px;
    position: relative;
    vertical-align: top;
    width: 119px;
    z-index: 99;

    @include media('<tablet') {
      display: block;
      margin: 0 auto 10px;
    }

    &--number {
      @include font(48px, 41px);
      display: block;
      font-weight: 700;
      margin: 0 0 1px;
      text-transform: uppercase;
    }

    &--text {
      @include font(16px, 20px);
      display: block;
      text-transform: uppercase;
    }

  }
}






/* stylelint-disable */
.features {
  h1 {
    sup {
      font-size: 19px;
      text-transform: none;
    }
  }
}
.video-section  {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .inner {
    width: 100%;
  }
  .play {
    border: 3px solid $white;
    display: block;
    height: 98px;
    margin: 0 auto;
    position: relative;
    width: 107px;
    left: -7px;
    top: 4px;
    font-size: 0;

    @include media('<desktop') {
      width: 40px;
      height: 40px;
    }

    &:after {
      border-color: transparent transparent transparent $white;
      border-style: solid;
      border-width: 25.5px 0 25.5px 28px;
      content: '';
      left: 50%;
      margin: -25px 0 0 -13px;
      position: absolute;
      top: 50%;

      @include media('<desktop') {
        border-width: 7px 0 7px 10px;
        margin: -7px 0 0 -4px;
      }
    }
  }
  a {
    &:hover {
      .play { border-color: $orange;}
      .video-caption__text {color: $orange;}
      .play {
        &:after { border-color: transparent transparent transparent $orange;}
      }
    }
  }
}
.counter__li {
  .inner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .inner2 {
      width: 68px !important;
      text-align: center;
      border-radius: 100%;
    }
  }
}
.counter {
  position: relative;
  &:after {
    background: $orange;
    content: '';
    height: 10px;
    left: 0;
    margin: -5px 0 0;
    position: absolute;
    right: 0;
    top: 50%;

    @include media('<tablet') {
      left: 50%;
      right: auto;
      top: 0;
      bottom: 0;
      margin: 0 0 0 -5px;
      height: auto;
      width: 10px;
    }
  }
}

.counter__li .inner .inner2 span {
  background: none !important;
}
.video-section__holder {
  height: auto !important;
}