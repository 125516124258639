// Custom styles here
// Note, if this file exceeds 200 lines of code, it must be broken up into multiple files and imported using @import


$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 992px,
  'widescreen': 1200px,
  'extrawidescreen': 1500px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);


@import 'header';
@import 'navigation';
@import 'visual';
@import 'footer';
@import 'landing';
@import 'section';
@import 'about-area';
@import 'main-area';
@import 'blocks';
@import 'internal';
@import 'accordion';
