/** @define nav */
.nav {
  float: right;
  padding: 24px 0 21px;
  position: relative;

  @include media('<desktop') {
    padding-top: 35px;
  }
  
}

/** @define nav-list */
.nav-list {
  @include font(16px, 20px);
  @include listreset;
  float: right;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  @include media('<widescreen') {
    font-size: 15px;
  }

  &__li {
    float: left;
    margin-left: 45px;
    position: relative;

    @include media('<widescreen') {
      margin-left: 16px;
    }

    &--a {
      color: $brown;

      &:hover {
        color: $orange;
      }
    }
  }
}


/* stylelint-disable */
/* .nav-list {
  &.collapse { display: block;}
}*/ 
@include media('<desktop') {
  .nav-list {
    position: absolute;
    top: 56px;
    right: 0px;
    background: $white;
    width: 260px;
    padding: 20px 15px 40px;
    float: none;
    height: 79vh;
    overflow: auto;
    
    li {
      float: none !important;
      margin: 0 -15px !important;
      padding: 0;
      a {
        color:$black;
        display: block;
        padding: 10px 15px;
        @include media('<desktop') {
          &::hover { color:$white;}
        }
      }
    }
  }
}
.navbar-toggle {
    position: absolute;
    right: 0;
    top: 12px;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid $gray;
    border-radius: 4px;
    display: none;
    .icon-bar {
      display: block;
      width: 24px;
      height: 2px;
      border-radius: 1px;
      background: $gray;
      margin-bottom: 7px;
      &.last {margin-bottom: 0;}
  }
  @include media('<desktop') {
      display: block;
      margin: 0;
    }
}
nav.nav:after {
  content: '';
  bottom: 0;
  height: 200%;
  background: #fff;
  width: 260px;
  position: absolute;
}
.subdropdown__list {
  &:hover,
  &.current-menu-item,
  &.active {
    a { color: $orange;}
  }
  a {
    color: $black !important;
    &::hover {
      color: $orange !important;
    }
  }
  
}
.top-linkli {
  list-style: none;
}
.top-link .textwidget {
  float: left;
}
.sub-menu {
  background: #fff;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  list-style: none;
  display: none;
  left: 0;
  padding: 25px 0 0;
  position: absolute;
  top: 100%;
  width: 210px;
  @include media('<desktop') {
    width: 100%;
    position: relative;
    padding: 0;
    font-size: 14px;
    line-height: 15px;
    display: block !important;
  }
}

.sub-menu li {
  border-bottom: 1px solid #ccc;
  float: none;
  margin: 0;
  padding: 10px;
  text-align: center;
  @include media('<desktop') {
    margin: 0 !important;
    padding: 0;
    text-align: left;
  }
}

.sub-menu li:last-child {
  border-bottom: 0;
}

.menu-item {
  position: relative;
}

.menu-item:hover .sub-menu {
  display: block;
}
.menu-item:hover .sub-menu li ul {
  display: none;
}
.menu-item:hover .sub-menu li:hover ul {
  display: block;
}
@media only screen and ( max-width: 991px ){
  .nav{
    padding-bottom: 27px;
  }
  .sub-menu .sub-menu {
    background: #dbdbdb;
    left: 0 !important;
    display: block !important;
  }
  .sub-menu {
    background: #f1f1f1;
  }
}

