// custom variables and override bootstrap variables
$gray: #333;
$gray2: #322600;
$gray3: #777;
$gray4: #e4e4e4;
$gray5: #555;
$gray6: #d8d8d8;
$off-gray: #f3f3f3;
$brown: #322600;
$yellow: #f5d427;
$black: #000;
$white: #fff;
$offwhite: #fafafa;
$dark-green: #008245;
$light-blue: #6299d7;
$blue: #000948;
$green: #005806;
$orange: #da631b;
