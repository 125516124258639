/** @define section */
.section {
  padding: 85px 0 91px;
  position: relative;

  @include media('<desktop') {
    padding: 40px 0;
  }

  &__img-holder {
    height: 340px;
    margin: 2px 17px 0 0;
    position: relative;

    @include media('<desktop') {
      height: auto;
      margin: 0 0 20px;
    }

    &--zippy {
      bottom: 9px;
      height: 376px;
      position: absolute;
      right: -274px;
      width: 298px;

    }

    &--img {
      bottom: 0;
      display: block;
      height: auto;
      position: absolute;
      width: 100%;

      @include media('<desktop') {
        position: static;
      }
    }
  }
}

/** @define form-area */
.form-area {
  margin-left: 20px;
  position: relative;

  &__heading {
    @include font(24px, 28px);
    color: $orange;
    letter-spacing: 0.4px;
    margin: 0 0 16px;
    text-transform: uppercase;
  }
}


/* stylelint-disable */
.form-area {
  @include media('<desktop') {
    margin-left: 0;
  }
  ul {
    @include font(16px, 20px);
    @include listreset;
    font-weight: 300;

    li {
      margin: 0 0 9px;
    }

    label {
      display: block;
      margin: 0 0 5px;
      font-weight: 300;
    }
  }
  input[type="submit"] { margin-top: 15px;}
}
.section__img-holder--zippy {
  @include media('<desktop') {
   display: none;
  }
}
.section {
  @include media('<desktop') {
   .pull-right {
      float: none !important;
    }
  }
}


