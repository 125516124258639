/** @define visual */
.visual {
  position: relative;

  &__img {
    display: block;
    height: auto;
    width: 100%;
  }
}

/** @define tagline */
.tagline {
  background: $orange;
  padding: 20px 0;
  position: relative;
  text-align: center;

  @include media('<tablet') {
    padding: 10px 0;
  }

  &__link {
    color: $white;
    font-weight: 700;
    margin-right: 46px;
    position: relative;
    text-transform: uppercase;

    @include media('<tablet') {
      margin-right: 0;
    }

    &::after {
      background: $white;
      bottom: 3px;
      content: '';
      position: absolute;
      right: -23px;
      top: 4px;
      width: 2px;

      @include media('<tablet') {
        display: none;
      }
    }
  }

  &__text {
    @include font(28px, 32px);
    color: $white;
    font-weight: 300;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0 0 0 53px;

    @include media('<tablet') {
      padding: 0;
      text-align: center;
    }

  }
}


/* stylelint-disable */
.tagline {
  a {
    &:hover { color: $yellow;}
  }
  i {
    position: absolute;
    left: -36px;
    top: 4px;
    font-size: 24px;
    transform: rotate(90deg);
    display: block;
  }
  span {
    @include media('<tablet') {
      display: block;
      font-size: 24px;
      margin: 4px 0 0;
    }
  }
}