/** @define footer */
.footer {
  background: $brown;
  padding: 18px 0 40px;
  position: relative;

  &::after {
    background: url('../front-end-src/images/bg-footer.png') no-repeat;
    background-position: 50% 0;
    content: '';
    height: 131px;
    left: 0;
    position: absolute;
    right: 0;
    top: -131px;
  }
}

/** @define col-holder */
.col-holder {
  display: flex;
  margin-bottom: 21px;
  position: relative;

  @include media('<tablet') {
    display: block;
  }
}

/** @define footer-col */
.footer-col {
  float: left;
  position: relative;
  width: 25.5%;

  @include media('<tablet') {
    float: none;
    width: 100%;
  }

}

/** @define footer-nav */
.footer-nav {
  @include font(12px, 15px);
  @include listreset;
  color: $white;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 3px 18% 0 22%;
  position: relative;
  text-transform: uppercase;
  
  @include media('<widescreen') {
    padding: 3px 15px 0;
  }

  &__li {
    margin: 0 0 23px;

    @include media('<tablet') {
      text-align: center;
    }
  }
  
}

/** @define footer-bottom */
.footer-bottom {
  position: relative;

  &__img {
    float: left;

    @include media('<widescreen') {
      float: none;
      margin-bottom: 15px;
      text-align: center;
    }
  }

  &__list {
    @include font(12px, 15px);
    @include listreset;
    color: $white;
    font-weight: 700;
    letter-spacing: 2px;
    overflow: hidden;
    padding-top: 32px;
    text-transform: uppercase;
    
    @include media('<widescreen') {
      text-align: center;
    }

    @include media('<desktop') {
      @include font(10px, 15px);
      letter-spacing: 0.5px;
    }


    &--li {
      float: left;
      padding: 0 20px;
      position: relative;

      @include media('<widescreen') {
        display: inline-block;
        float: none;
        vertical-align: top;
      }

      @include media('<desktop') {
        padding: 0 10px;
      }
    }
  }
}

/** @define zippy2 */
.zippy2 {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 1;

  @include media('<extrawidescreen') {
    display: none;
  }
}







/* stylelint-disable */
.footer {
  a { 
    color: $white;

    &:hover {
      color: $orange;
    }
  }
}
.footer-col {
  &:after {
    background: #6b5100;
    bottom: 15px;
    content: '';
    left: 0;
    position: absolute;
    top: 5px;
    width: 1px;
    @include media('<tablet') {
      display: none;
    }
  }
  &:first-child {
    width: 20.2%;
    @include media('<tablet') {
      float: none;
      width: 100%;
    }
    &:after { display: none;}
    .footer-nav { padding-left: 0; padding-right: 0;}
    li {
      margin-bottom: 18px;
    }
    a {
      display: inline-block;
      vertical-align: top;
      padding: 0 15px;
      background: $orange;
      color: $white;
      height: 27px;
      border-radius: 13px;
      @include font(14px, 27px);

      @include media('<widescreen') {
        font-size: 11px;
      }

      @include media('<desktop') {
        letter-spacing: 0;
      }

      @include media('<tablet') {
        display: block;
        text-align: center;
      }
      &:hover { 
        background: $white;
        color: $orange;
      }

    }
  }
  &:last-child {
    @include media('<tablet') {
      width: 260px;
      margin: 0 auto;
    }
    .footer-nav {
      li {
        @include media('<tablet') {
          text-align: left !important;
        }
        position: relative;
        padding: 4px 0 0 24px;
        i {
          position: absolute;
          left: 0;
          top: 4px;
          background: url('../front-end-src/images/ico-sprite.png') no-repeat 0 -3px;
          width: 12px;
          height: 12px;
          display: block;

          &.tell {
            background-position: -27px 0;
          }
          &.email {
            background-position: -51px -3px;
            width: 14px;
            height: 12px;
          }
        }
      }
    }
  }
}
.footer-bottom__img {
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 14px;
  }
}
.footer-bottom__list--li {
  position: relative;

  @include media('<desktop') {
    margin-bottom: 10px;
  }


  &:after {
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 3px;
    width: 1px;
    content: '';
    background: $white;
  }
  &:first-child {
    &:after { display: none;}
  }
  a {
    color: $white;
    &:hover {
      color: $orange;
    }
    i {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      font-size: 13px;
    }
  }
} 
.footer-bottom__list--li {
  &:first-child { padding-left: 33px;}
}
