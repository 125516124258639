/** @define about-area */
.about-area {
  background: $off-gray;
  padding: 92px 0 74px;
  position: relative;

  @include media('<desktop') {
    padding: 40px 0;
  }

  &__img-holder {
    box-shadow: 20px 20px 0 0 #d8d8d8;
    float: right;
    margin-right: 2.3%;
    position: relative;

    @include media('<desktop') {
      box-shadow: none;
      float: none;
      margin-right: 0;
    }
    

    &--img {
      display: block;
      height: auto;
      width: 100%;

      @include media('<desktop') {
        margin: 0 auto 80px;
        max-width: 100%;
        width: auto;
      }
      
    }

    &--nuts {
      bottom: -61px;
      position: absolute;
      right: -68px;
      z-index: 99;

      @include media('<widescreen') {
        right: 10px;
      }

      @include media('<desktop') {
        margin-right: 0;
        right: 0;
      }
    }
  }
}

/** @define about-description */
.about-description {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;

  &__inner {
    padding-top: 24px;
    text-align: right;
    width: 100%;

    @include media('<desktop') {
      text-align: center;
    }
    
  }

  &__heading {
    letter-spacing: 0.6px;
    margin: 0 0 31px;
    padding-bottom: 22px;
    position: relative;

    &::after {
      background: $orange;
      bottom: 0;
      content: '';
      height: 3px;
      position: absolute;
      right: 0;
      width: 58px;

      @include media('<desktop') {
        margin-right: -24px;
        right: 50%;
        
      }
    }
  }

  &__item {
    @include font(16px, 23px);
    color: $black;
    font-weight: 300;
    margin: 0 0 20px;
    overflow: hidden;
    padding-left: 10%;

    @include media('<desktop') {
      padding-left: 0;
    }
  }
}



/* stylelint-disable */

.about-description__item {
  h3 {
    margin: 0 0 8px;
  }
  p { margin: 0 0 13px;}
}
.disflex {
  @include media('<desktop') {
    display: block !important;
  }
}

